<template>
  <v-container fluid class="fill-height">
    <v-row class="text-center">
    

      <v-col col=12>
        <h1 class="display-2 font-weight-bold mb-3">
          반갑습니다!
        </h1>
        <p class="subheading font-weight-regular">
          by Orclord
       
        </p>
      
        <v-img
          :src="require('../assets/snorlax-seeklogo.svg')"
          class="my-3"
          contain
          height="200"
        />
        <v-col cols="auto">
        <v-btn fab icon="mdi-arrow-right" size="x-large" class="mt-5" color="black" dark to="/about"></v-btn>
      </v-col>

      </v-col>

    

     
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'HomePage',

  data: () => ({
    
  }),
}
</script>
