<template>
  

  <HomePage />

</template>

<script>
import { defineComponent } from 'vue';

// Components
import HelloWorld from '../components/HelloWorld.vue';
import HomePage from '../components/HomePage.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    HelloWorld,
    HomePage,
  },
});
</script>
